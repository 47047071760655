// Contextual backgrounds
@mixin bg-variant($parent, $color) {
  #{$parent} {
    background-color: $color !important;
  }
  a#{$parent},
  button#{$parent} {
    @include hover-focus {
      background-color: darken($color, 10%) !important;
    }
  }
}

@mixin bg-gradient-variant($parent, $color) {
  #{$parent} {
    background: linear-gradient(
      87deg,
      $color 0,
      $color 100%
    ) !important;
  }
}

// Shape (svg) fill colors
@each $color, $value in $theme-colors {
  .fill-#{$color} {
    fill: $value;
  }

  .stroke-#{$color} {
    stroke: $value;
  }
}
